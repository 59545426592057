<template>
  <div>
    <h1>Insured</h1>
    <div>
      <!-- search input -->
      <div
        class="search-wrapper, col-5"
        style="margin-bottom: 16px;"
      >
        <b-form-group>
          <div class="d-flex align-items-left">
            <b-form-input
              v-model="searchNameTerm"
              placeholder="Search by Insured Name"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <hr>
      <div
        class="row"
        style="margin-bottom: 8px;"
      >
        <div class="col-6">
          <!-- add button -->
          <div>
            <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                style="
                justify-content: space-around;
                display: flex;
                border-radius: 0;
              "
                @click="$router.push('/insured/add')"
              >
                <span>+ Add Insured</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-3 center-justified">
          View All Statuses <toggle-button @change="activeClients = $event.value" />
        </div>
        <div class="col-3">
          <!-- search input -->
          <b-form-group>
            <div class="d-flex align-items-right">
              <label class="mr-1" />
              <b-form-input
                v-model="searchFilterTerm"
                placeholder="Filter Insured"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'Name'"
            class="text-nowrap"
          >
            <router-link
              :to="'/insured/view/' + props.row.ClientID"
            >{{ props.row.Name }}</router-link>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>
                    <router-link
                      :to="'/insured/view/' + props.row.ClientID"
                    >View Insured</router-link>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>
                    <router-link
                      :to="'/insured/edit/' + props.row.ClientID"
                    >Edit Insured</router-link>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="false"
                  @click="deleteClientWithPrompt(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }
                "
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-delete-client"
      ok-only
      ok-variant="danger"
      ok-title="Delete Client"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Client"
      @ok="confirmDeleteClient()"
    >
      <div v-if="clientToDelete !== null">
        Are you sure you want to delete client {{ clientToDelete.Name }}
        {{ clientToDelete.LastName }} ({{ clientToDelete.EMail }})? This cannot be
        undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      clientToDelete: null,
      activeClients: false,
      columns: [
        {
          label: "Insured",
          field: "Name",
        },
        {
          label: "Status",
          field: "CRMStatus",
        },
        {
          label: "Deal Owner",
          field: "DOUsername",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchNameTerm: "",
      searchFilterTerm: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getInsuredSearch: "search/getInsuredSearch",
      getInsuredFilter: "search/getInsuredFilter",
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchNameTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClientPage(1);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
      this.setInsuredSearch(val)
    },
    searchFilterTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadClientPage(1);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
      this.setInsuredFilter(val)
    },
    activeClients: function (val) {
      this.loadClientPage(1);
    }
  },
  mounted() {
    this.searchNameTerm = this.getInsuredSearch;
    this.searchFilterTerm = this.getInsuredFilter;
  },
  methods: {
    ...mapMutations({
      setInsuredSearch: "search/setInsuredSearch",
      setInsuredFilter: "search/setInsuredFilter",
    }),
    loadClientPage(pageNumber) {
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      if (this.searchFilterTerm.length || this.searchNameTerm.length) {
        apiService
            .get(
                "insured/search?searchNameTerm=" +
                encodeURIComponent(this.searchNameTerm) +
                "&searchFilterTerm=" +
                encodeURIComponent(this.searchFilterTerm) +
                "&skip=" +
                startIndex +
                "&max=" +
                this.pageLength +
                "&activeClients=" +
                !this.activeClients
            )
            .then((response) => {
              this.loadClientResults(response.data);
            });
      }
    },
    loadClientResults(responseData) {
      this.rows = responseData.Clients;
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    onPageChanged(bvEvent, page) {
      this.loadClientPage(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadClientPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },
    deleteClientWithPrompt(clientObj) {
      this.clientToDelete = clientObj;
      this.$bvModal.show("modal-delete-client");
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
[dir] .form-group {
  margin-bottom: 0;
}
.btn--add-users,
.input-group-text {
  border-radius: 0;
}

[dir] .input-group > .form-control {
  height: 2.8em;
}
</style>
